<script setup>
// 导入
import { ref, defineProps, onBeforeMount, watch, watchEffect } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useWalletStore from '@/store/wallet';
import { fiat_deposit } from '@/assets/network/service/wallet';
import '@/components/wallet/module/MyElement.css';
import TabSelector from '@/components/wallet/module/TabSelector';
import CoinSelector from '@/components/wallet/module/CoinSelector';
import ChainSelector from '@/components/wallet/module/ChainSelector';
import AddressView from '@/components/wallet/module/AddressView';
import NociceView from '@/components/wallet/module/NociceView';
import ButtonItem from '@/components/wallet/module/ButtonItem';
import { Vue3Lottie } from 'vue3-lottie';
import loadingAniJson from '@/assets/animation/export_sclogo_wingame.json';
import { getBrowser } from '@/assets/tools/CommonTool';
import { useAppStore } from '@/store/store';
import { kwaiEventService } from '@/assets/network/service/user';
import { computed } from 'vue';
import BigNumber from 'bignumber.js';
import { onUnmounted } from 'vue';
import { onMounted } from 'vue';
// 传参
const props = defineProps({
  tabIndex: {
    type: String,
    default: 'Crypto'
  },
  defSymbol: {
    type: String
  }
});
// 属性
const { t } = useI18n();
const route = useRoute();
const appStore = useAppStore();
const walletStore = useWalletStore();
const showKESPhoneNumber = ref(false);
const kesPhoneNumber = ref('');
const tabSelect = ref(props.tabIndex);
const showIframe = ref(false);
const iframeSrc = ref('');
const isIframeLoading = ref(false);
// 计算属性
watch([() => walletStore.cryptoSelectItem, () => walletStore.cryptoChainIndex], () => {
  if (!walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].address) {
    walletStore.getCryptoChainAddress();
  }
});
watch([() => walletStore.fiatSelectItem], () => {
  showKESPhoneNumber.value = walletStore.fiatSelectItem.symbol == 'KES';
  walletStore.getFiatDepositOpt();
});

const extraAmount = computed(() => {
  if (!walletStore.fiatDepositAmount) {
    return 0;
  }
  if (!(/^-?\d+(\.\d+)?$/.test(walletStore.fiatDepositAmount) && !isNaN(parseFloat(walletStore.fiatDepositAmount)))) {
    return 0;
  }
  if (walletStore.fiatDepositOpt.length === 0) {
    return 0;
  }

  const conditionArr = walletStore.fiatDepositOpt.filter((level) => level.amount <= walletStore.fiatDepositAmount).sort((a, b) => a.amount - b.amount);

  if (conditionArr.length === 0) {
    return 0;
  }

  if (!conditionArr[conditionArr.length - 1].gift_rate && !conditionArr[conditionArr.length - 1].gift_amount) {
    return 0;
  }
  if (conditionArr[conditionArr.length - 1].gift_amount && !conditionArr[conditionArr.length - 1].gift_rate) {
    return BigNumber(conditionArr[conditionArr.length - 1].gift_amount);
  }
  if (conditionArr[conditionArr.length - 1].gift_rate && !conditionArr[conditionArr.length - 1].gift_amount) {
    return BigNumber(walletStore.fiatDepositAmount).multipliedBy(BigNumber(conditionArr[conditionArr.length - 1].gift_rate));
  }

  return BigNumber(walletStore.fiatDepositAmount)
    .multipliedBy(BigNumber(conditionArr[conditionArr.length - 1].gift_rate))
    .plus(BigNumber(conditionArr[conditionArr.length - 1].gift_amount));
});
// 生命周期
onBeforeMount(async () => {
  await walletStore.getTokensInfo(1);
  if (props.defSymbol) {
    const res = walletStore.tokensInfoList.filter((item) => item.symbol.toLowerCase() == props.defSymbol.toLowerCase());
    if (props.tabIndex == 'Crypto') {
      walletStore.cryptoSelectItem = res[0];
    }
    if (props.tabIndex == 'Fiat') {
      walletStore.fiatSelectItem = res[0];
    }
  }
});
watchEffect(() => {
  if (tabSelect.value == 'Crypto' && walletStore.cryptoSelectItem) {
    const params = {
      kwai_pixel_id: appStore.kwaiPixelId,
      kwai_token: appStore.kwaiToken,
      click_id: appStore.kwaiClickId,
      event_name: 'deposit_now',
      symbol: walletStore.cryptoSelectItem.symbol,
      amount: 0
    };
    kwaiEventService(params);
  }
  if (walletStore.depositSuccess) {
    toast(t('deposit_success'), { autoClose: 2000, type: 'success' });
    walletStore.depositSuccess = false;
    iframeSrc.value = '';
    showIframe.value = false;
  }
});
// 方法
const clickTab = (type) => {
  tabSelect.value = type;
};
const clickGear = (item) => {
  walletStore.fiatDepositAmount = item.amount;
};
const getFiatDepositLimit = () => {
  //{{ `${walletStore.fiatSelectItem.chains[0].min_deposit_amount} - ${walletStore.fiatSelectItem.chains[0].max_deposit_amount} ${walletStore.fiatSelectItem.symbol}` }}
  let min, max;
  if (walletStore.fiatSelectItem.chains[0].min_deposit_amount > 0) {
    min = walletStore.fiatSelectItem.chains[0].min_deposit_amount;
  } else {
    min = '0';
  }
  if (walletStore.fiatSelectItem.chains[0].max_deposit_amount > 0) {
    max = walletStore.fiatSelectItem.chains[0].max_deposit_amount;
  } else {
    max = '∞';
  }
  if (min == '0' && max == '∞') {
    return '';
  } else {
    return `${min} - ${max} ${walletStore.fiatSelectItem.symbol}`;
  }
};
const clickDepositNow = async () => {
  iframeSrc.value = '';
  showIframe.value = false;
  isIframeLoading.value = false;
  if (walletStore.isLoading) {
    return;
  }
  if (!walletStore.fiatDepositAmount) {
    toast(t('deposit_amount_is_required'), { autoClose: 2000, type: 'error' });
    return;
  }
  if (!Number(walletStore.fiatDepositAmount)) {
    toast(t('amount_is_error'), { autoClose: 2000, type: 'error' });
    return;
  }
  if (Number(walletStore.fiatDepositAmount) < Number(walletStore.fiatSelectItem.chains[0].min_deposit_amount)) {
    toast(t('the_minimum_recharge') + ' ' + walletStore.fiatSelectItem.chains[0].min_deposit_amount + walletStore.fiatSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  }
  if (walletStore.fiatSelectItem.chains[0].max_deposit_amount > 0 && Number(walletStore.fiatDepositAmount) > Number(walletStore.fiatSelectItem.chains[0].max_deposit_amount)) {
    toast(t('the_maximum_recharge') + ' ' + walletStore.fiatSelectItem.chains[0].max_deposit_amount + walletStore.fiatSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  }
  if (walletStore.fiatSelectItem.symbol == 'KES' && !/^7\d{8}$/.test(kesPhoneNumber.value)) {
    toast(t('please_enter__seven'), { autoClose: 2000, type: 'error' });
    return;
  }
  if (walletStore.fiatSelectItem.symbol == 'PHP' && walletStore.fiatDepositChannelType == '') {
    toast(t('channelType_amount_is_required'), { autoClose: 2000, type: 'error' });
    return;
  }
  const par = {
    symbol: walletStore.fiatSelectItem.symbol,
    amount: Number(walletStore.fiatDepositAmount),
    pageUrl: route.path,
    channel_type: walletStore.fiatDepositChannelType
  };
  if (walletStore.fiatSelectItem.symbol == 'KES') {
    par.phone_number = '254' + kesPhoneNumber.value;
  }
  walletStore.isLoading = true;
  try {
    const browser = getBrowser();
    console.log('当前浏览器是：', browser);
    // let win;
    // if (browser == 'Safari') {
    //   win = window.open('', '_blank');
    // }
    const res = await fiat_deposit(par);
    walletStore.isLoading = false;
    if (res.data.code == 200) {
      // if (browser == 'Safari') {
      //   win.location = res.data.data.cashierUrl;
      // } else {
      //   window.open(res.data.data.cashierUrl, '_blank');
      // }

      iframeSrc.value = res.data.data.cashierUrl;
      showIframe.value = true;
      isIframeLoading.value = true;

      const params = {
        kwai_pixel_id: appStore.kwaiPixelId,
        kwai_token: appStore.kwaiToken,
        click_id: appStore.kwaiClickId,
        event_name: 'deposit_now',
        symbol: walletStore.fiatSelectItem.symbol,
        amount: walletStore.fiatDepositAmount
      };
      kwaiEventService(params);
    } else {
      toast(res.data.msg, { autoClose: 2000, type: 'error' });
    }
  } catch (error) {
    walletStore.isLoading = false;
    const msg = error.response?.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response?.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 2000, type: 'error' });
  }
};

// 判断iframe是否重定向到了wingame.com
const iframeRef = ref(null);
const isWingameDomain = ref(false);
const checkInterval = ref(null);

const handleIframeLoad = () => {
  isIframeLoading.value = false;
  startDomainCheck();
};

const handleIframeError = () => {
  isIframeLoading.value = false;
  console.error('iframe加载失败');
};

const startDomainCheck = () => {
  clearInterval(checkInterval.value);
  checkInterval.value = setInterval(() => {
    try {
      const currentUrl = iframeRef.value.contentWindow.location.href;
      isWingameDomain.value = currentUrl.includes('wingame.com');
      if (isWingameDomain.value) {
        console.log('iframe最终加载到了wingame.com域名');
        clearInterval(checkInterval.value);
        showIframe.value = false;
      }
    } catch (error) {
      console.error('无法访问iframe内容:', error);
    }
  }, 1000); // 每秒检查一次
};

onMounted(() => {
  window.addEventListener('message', handlePostMessage);
});

onUnmounted(() => {
  window.removeEventListener('message', handlePostMessage);
  clearInterval(checkInterval.value);
});

const handlePostMessage = (event) => {
  if (event.data === 'iframeLoaded' && event.origin.includes('wingame.com')) {
    isWingameDomain.value = true;
    console.log('收到来自wingame.com的消息，iframe已加载完成');
    clearInterval(checkInterval.value);
    showIframe.value = false;
  }
};

watch(showIframe, (newVal) => {
  if (!newVal && checkInterval.value) {
    clearInterval(checkInterval.value);
  }
});
</script>

<template>
  <div v-if="walletStore.tokensInfoList && walletStore.tokensInfoList.length > 0" class="relative box-border max-w-[480px] bg-[#191F33]">
    <!-- 标题 -->
    <div class="flex flex-row justify-between items-center mb-[15px]">
      <div class="text-[18px] text-[#ffffff] font-[700]">{{ t('deposit') }}</div>
      <!-- <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
          <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
        </svg>
      </div> -->
    </div>
    <!-- tab选项 -->
    <TabSelector class="mb-[15px]" :tabIndex="tabSelect" @change="clickTab" />
    <!-- 加密货币 -->
    <div v-show="tabSelect == 'Crypto'">
      <div class="flex justify-between gap-[10px] mb-[15px]">
        <CoinSelector class="w-1/2" :titleText="t('Deposit Currency').toUpperCase()" :tabIndex="tabSelect" />
        <ChainSelector class="w-1/2" :titleText="t('Choose Network').toUpperCase()" />
      </div>
      <AddressView class="mb-[15px]" :titleText="t('Deposit Address').toUpperCase()" />
      <NociceView type="Deposit" />
    </div>
    <!-- 法币 -->
    <div v-show="tabSelect == 'Fiat'">
      <div class="flex mb-[15px]">
        <CoinSelector class="w-full flex justify-between items-center" :titleText="t('Deposit Currency').toUpperCase()" :tabIndex="tabSelect" />
      </div>
      <div v-if="showKESPhoneNumber" class="mb-[15px]">
        <div class="mb-[5px] text-[12px] text-[#5B7BAB] text-left font-[700]">{{ t('phone_number').toUpperCase() }}</div>
        <el-input v-model="kesPhoneNumber" class="my-el-css" size="large" clearable>
          <template #prefix>
            <div class="text-[#fff] text-[14px] font-[400] text-end">+254</div>
          </template>
        </el-input>
      </div>
      <div v-if="walletStore.fiatSelectItem.symbol == 'PHP'" class="flex mb-[15px] w-full flex justify-between items-center">
        <div class="text-[12px] text-[#5B7BAB] text-left font-[700] mb-[5px]">{{ t('ChannelType').toUpperCase() }}</div>
        <el-select v-model="walletStore.fiatDepositChannelType" class="my-el-css w-1/2" :placeholder="t('Please select')" size="large" :teleported="false">
          <el-option v-for="(item, index) in walletStore.fiatDepositChannelTypeList" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <div class="mb-[15px]">
        <div class="flex justify-between items-center text-[12px] text-[#5B7BAB] text-right font-[700] mb-[5px]">
          <div>{{ t('Deposit Amount').toUpperCase() }}</div>
          <div>{{ getFiatDepositLimit() }}</div>
        </div>
        <el-input v-model="walletStore.fiatDepositAmount" class="my-el-css" size="large" clearable>
          <template #prefix>
            <img class="w-[16px]" :src="walletStore.fiatSelectItem.icon_url" />
          </template>
          <template v-if="extraAmount > 0" #append>
            <div class="flex justify-between items-center text-[12px] text-[#5B7BAB] text-right font-[700]">{{ `Extra +${extraAmount}` }}</div>
          </template>
        </el-input>
      </div>
      <div class="grid grid-cols-3 gap-[10px] mb-[15px]">
        <div class="rounded-[4px] relative h-[60px] flex items-center px-[20px] gap-[10px] cursor-pointer" :class="walletStore.fiatDepositAmount === item.amount ? 'bg-[#3E4B79]' : 'bg-[#222942]'" v-for="(item, index) in walletStore.fiatDepositOpt" :key="index" @click="clickGear(item)">
          <img class="w-[26px] border-none" :style="walletStore.fiatDepositAmount === item.amount ? 'filter: drop-shadow(0px 0px 2px #1184FA) drop-shadow(0px 0px 12px #1184FA);' : ''" :src="walletStore.fiatSelectItem.icon_url" />
          <div class="text-[#aac4ed]" :style="walletStore.fiatDepositAmount === item.amount ? 'color: #CEE2FF; text-shadow: 0px 0px 2px #1184FA, 0px 0px 12px #1184FA;' : 'color: #aac4ed'">{{ item.amount }}</div>
          <div v-if="item.desc" class="absolute top-0 right-0 bg-[#e72c2c] rounded-tr-[4px] rounded-bl-[4px] text-[12px] text-[#fff] px-[5px] py-[2px]">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <div class="w-full mb-[15px]">
        <ButtonItem :btnText="t('deposit_now')" @click="clickDepositNow" />
      </div>
      <NociceView type="Deposit" />
    </div>
    <div v-if="walletStore.isLoading" class="fixed left-0 top-0 w-full h-full flex justify-center items-center">
      <vue3-lottie :animationData="loadingAniJson" :width="150" :height="150"></vue3-lottie>
    </div>
    <el-dialog v-model="showIframe" :close-on-click-modal="false" class="bg-[#191F33] text-left w-full md:w-[500px]" align-center>
      <iframe ref="iframeRef" :src="iframeSrc" :frameborder="0" class="mt-[8px] w-full h-[600px] md:h-[700px]" @load="handleIframeLoad" @error="handleIframeError"></iframe>
      <div v-if="isIframeLoading" class="fixed left-0 top-0 w-full h-full flex justify-center items-center">
        <vue3-lottie :animationData="loadingAniJson" :width="150" :height="150"></vue3-lottie>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
/* .deposit-container {
  .tab-selected {
    background-color: #222942;
    color: #ffffff;
    text-shadow:
      0px 0px 8px #1184fa,
      0px 0px 12px #1184fa;
  }
  .tab-unselected {
    background-color: transparent;
    color: #5b7bab;
  }
  .copy-btn {
    cursor: pointer;
    background-color: #222942;
    color: #fff;
    box-shadow:
      0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
      0px 0px 12px 0px #142034 inset;
  }
  .deposit-now-btn {
    cursor: pointer;
    background-color: #3aa1ff;
    color: #121829;
    box-shadow:
      0px 0px 10px 0px rgba(17, 132, 250, 0.4),
      0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
      0px 0px 12px 0px #0d52b2 inset;
  }
} */
</style>
