<!-- eslint-disable no-async-promise-executor -->
<script setup>
import { ref, onMounted, watch } from 'vue';
import freeTurntableDialog from '@/components/freeTurntable';
import turntableDialog from '@/components/turntableDialog';
import { getTurntableList, getFreePromotionsService } from '@/assets/network/service/turntable';
import { getTosPopupConfig } from '@/assets/network/service/home';
import wgDialog from './components/showPopupConfig.vue';
import tgEntry from '@/components/notice/tgEntry.vue';
import { useTGStore, useAppStore } from '@/store/store';
import { useRoute } from 'vue-router';
import emitter from '@/assets/tools/eventBus';
import freeSpinResDialog from './components/freeSpinResDialog.vue';

defineOptions({
    name: 'promotionDialog'
})

const appStore = useAppStore();
const route = useRoute();

const activeInitiativeTab = ref(false);

// 所有弹框数据和状态
const promotionInfo = ref({
    freeSpin: { data: {}, isShow: false },
    spin: { data: [], isShow: false },
    tosPopup: {
        data: [], isShow: false
    }
});

// Tg引流弹窗因以前逻辑影响,还按之前逻辑放入store里
const TGStore = useTGStore();

// 处理弹框关闭事件：关闭当前弹框并尝试打开下一个
const handleDialogClose = async (dialogName) => {
    promotionInfo.value[dialogName].isShow = false; // 关闭当前弹框
    showNextDialog(); // 尝试显示下一个弹框
};

// 弹框拉取逻辑：每个函数返回一个 Promise，成功显示弹框时 resolve(true)，否则 resolve(false)
// 免费送转盘弹窗逻辑
const freeSpinLogic = () => {
    return new Promise(async (resolve) => {
        const inviteCode = route.query.referral_code || '';
        const FreePromotionsRes = await getFreePromotionsService({
            invite_code: inviteCode
        });
        if (FreePromotionsRes.data.data && FreePromotionsRes.data.data.exists && FreePromotionsRes.data.data.register_dialog_open) {
            promotionInfo.value.freeSpin.data = FreePromotionsRes.data.data;
            promotionInfo.value.freeSpin.isShow = true;
            resolve(true);
        } else {
            resolve(false); // 没有数据，跳到下一个弹窗
        }
    });
};

// 转盘弹窗逻辑
const spinLogic = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const spinRes = await getTurntableList();
            const records = spinRes.data.data.records;
            const isHomeTab = records.some(item => item.home_tab === true);

            if (isHomeTab && records.length > 0) {
                promotionInfo.value.spin.data = records;
                promotionInfo.value.spin.isShow = true;
                activeInitiativeTab.value = true;
                resolve(true);
            } else {
                resolve(false); // 没有数据，跳到下一个弹窗
            }
        } catch (error) {
            reject(error);
        }
    });
};

// 广告弹窗逻辑
const getTosPopupConfigLogic = () => {
    return new Promise(async (resolve, reject) => {
        if (route.fullPath !== '/') {
            resolve(false)
            return
        }
        try {
            const tosPopupRes = await getTosPopupConfig();
            if (tosPopupRes.data.popup_config && tosPopupRes.data.popup_config.items.length > 0) {
                promotionInfo.value.tosPopup.data = tosPopupRes.data.popup_config.items;
                promotionInfo.value.tosPopup.isShow = true;
                resolve(true);
            } else {
                resolve(false); // 没有数据，跳到下一个弹窗
            }
        } catch (error) {
            reject(error);
        }
    });
}

// Tg引流逻辑
const getTGConfig = () => {
    if (route.fullPath !== '/') return;
    TGStore.getConfigService('INDEX');
}

// 逐步显示下一个弹框的逻辑：每次显示一个，前一个关闭后再打开下一个
const currentDialogIndex = ref(0); // 用于追踪当前弹框的索引
const dialogsLogicQueue = [freeSpinLogic, spinLogic, getTosPopupConfigLogic, getTGConfig]; // 弹框逻辑队列

const showNextDialog = async () => {
    if (currentDialogIndex.value < dialogsLogicQueue.length) {
        const dialogLogic = dialogsLogicQueue[currentDialogIndex.value];
        const result = await dialogLogic(); // 执行当前弹框逻辑
        if (result) {
            currentDialogIndex.value += 1; // 成功显示后，准备下一个弹框
        } else {
            currentDialogIndex.value += 1;
            showNextDialog()
        }
    }
};

const resetData = () => {
    promotionInfo.value = {
        freeSpin: { data: {}, isShow: false },
        spin: { data: [], isShow: false },
        tosPopup: {
            data: [], isShow: false
        }
    }
}

// 初始时执行逻辑（无论是否登录）
const initLogic = () => {
    console.log('Initial logic execution');
    resetData();
    currentDialogIndex.value = 0;
    showNextDialog();
};

watch(() => appStore.isLogin, (newVal) => {
    if (newVal) {
        resetData()
        currentDialogIndex.value = 0
        showNextDialog();
    }

}, {
    immediate: false
})

// 初始化，页面挂载时启动弹窗逻辑
onMounted(() => {
    setTimeout(() => {
        if (appStore.isLogin === false) {
            initLogic(); // 未登录时执行
        }
    }, 2000)
    emitter.on('custom-showSpin', spinLogic)
});
</script>


<template>
    <div>
        <!-- 弹框1: 免费转盘弹框 -->
        <freeTurntableDialog v-model:isShow="promotionInfo.freeSpin.isShow" :data="promotionInfo.freeSpin.data"
            @close="handleDialogClose('freeSpin')"></freeTurntableDialog>

        <!-- 弹框2: 转盘弹框 -->
        <turntableDialog v-model="promotionInfo.spin.isShow" :initiativeTab="activeInitiativeTab"
            :list="promotionInfo.spin.data" @close="handleDialogClose('spin')"></turntableDialog>

        <!-- 弹窗3 广告弹窗-->
        <div style="width: 100vw; height: 100vh; position: absolute" v-if="promotionInfo.tosPopup.isShow">
            <wg-dialog :show="promotionInfo.tosPopup.isShow" :notifyDates="promotionInfo.tosPopup.data"
                @close="handleDialogClose('tosPopup')"> </wg-dialog>
        </div>
        <!-- 弹窗4 Tg引流弹窗-->
        <tg-entry :show="TGStore.show" :config="TGStore.config" @close="TGStore.updateShow(false)" />

        <!-- 弹窗5 免费送活动领取结果弹窗 -->
        <freeSpinResDialog></freeSpinResDialog>
    </div>
</template>