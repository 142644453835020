<script setup>
// 导入
import { ref, defineProps, onBeforeMount, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import { walletValid } from '@/assets/tools/wattle';
import '@/components/wallet/module/MyElement.css';
import { getCookieUserId } from '@/assets/tools/CommonTool';
import { userInfoService } from '@/assets/network/service/user';
import TabSelector from '@/components/wallet/module/TabSelector';
import CoinSelector from '@/components/wallet/module/CoinSelector';
import ChainSelector from '@/components/wallet/module/ChainSelector';
import FeeView from '@/components/wallet/module/FeeView';
import ButtonItem from '@/components/wallet/module/ButtonItem';
import NociceView from '@/components/wallet/module/NociceView';
import PixSelector from '@/components/wallet/module/PixSelector';
import DialogVerification from '@/components/wallet/module/DialogVerification';
import verifiedEmail from '@/pages/profile/components/verifiedEmail';
import bindMfa from '@/pages/profile/bindMfa';
import DialogPIN from '@/components/wallet/module/DialogPIN';
import { Vue3Lottie } from 'vue3-lottie';
import loadingAniJson from '@/assets/animation/export_sclogo_wingame.json';
// 传参
const props = defineProps({
  tabIndex: {
    type: String,
    default: 'Crypto'
  },
  defSymbol: {
    type: String
  }
});
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
const tabSelect = ref(props.tabIndex);
const userInfo = ref(null);
const openBindEmail = ref(false);
const openBindMfa = ref(false);
// 计算属性
watch([() => walletStore.cryptoSelectItem, () => walletStore.cryptoChainIndex], () => {
  if (!walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].address) {
    walletStore.getCryptoChainAddress();
  }
});
watch([() => walletStore.fiatSelectItem], () => {
  walletStore.getFiatWithdrawOpt();
  if (walletStore.fiatSelectItem.symbol == 'PHP') {
    walletStore.getBankNamePHP()
  }
});
// 生命周期
onBeforeMount(async () => {
  await walletStore.getTokensInfo(2);
  if (props.defSymbol) {
    const res = walletStore.tokensInfoList.filter((item) => item.symbol.toLowerCase() == props.defSymbol.toLowerCase());
    if (props.tabIndex == 'Crypto') {
      walletStore.cryptoSelectItem = res[0];
    }
    if (props.tabIndex == 'Fiat') {
      walletStore.fiatSelectItem = res[0];
    }
  }
});
// 方法
const getUserInfo = async (callback) => {
  if (walletStore.isLoading) {
    return;
  }
  const userId = getCookieUserId();
  walletStore.isLoading = true;
  const res = await userInfoService(userId);
  walletStore.isLoading = false;
  try {
    userInfo.value = res.data;
    callback();
  } catch (error) {
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 2000, type: 'error' });
  }
};
const clickTab = (type) => {
  tabSelect.value = type;
};
const clickCryptoWithdraw = async () => {
  let flag = walletValid(walletStore.cryptoWithdrawAddress, walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].platform_name);
  if (!flag) {
    toast(`${walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].platform_name} ${t('wallet_address_is_incorrect')}`, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!Number(walletStore.cryptoWithdrawAmount)) {
    toast(t('amount_is_error'), { autoClose: 2000, type: 'error' });
    return;
  } else if (Number(walletStore.cryptoWithdrawAmount) > walletStore.cryptoSelectItem.available) {
    toast(t('Cannot_exceed_the_available_limit'), { autoClose: 2000, type: 'error' });
    return;
  } else if (!walletStore.cryptoWithdrawAmount || walletStore.cryptoWithdrawAmount == 0 || Number(walletStore.cryptoWithdrawAmount) < Number(walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].min_withdraw_amount)) {
    toast(t('the_remaining_wagering_amount') + ' ' + walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].min_withdraw_amount + walletStore.cryptoSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  } else if (Number(walletStore.cryptoWithdrawAmount) > Number(walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].max_withdraw_amount) && walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].max_withdraw_amount != 0) {
    toast(t('the_maximum_withdrawal') + ' ' + walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].max_withdraw_amount + walletStore.cryptoSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  }
  getUserInfo(() => {
    if (!userInfo.value.verify_email || !userInfo.value.enable_otp) {
      walletStore.showDialogVerification = true;
    } else {
      walletStore.showDialogPIN = true;
    }
  });
};
const clickFiatWithdraw = async () => {
  if (!Number(walletStore.fiatWithdrawAmount)) {
    toast(t('amount_is_error'), { autoClose: 2000, type: 'error' });
    return;
  } else if (Number(walletStore.fiatWithdrawAmount) > walletStore.fiatSelectItem.available) {
    toast(t('Cannot_exceed_the_available_limit'), { autoClose: 2000, type: 'error' });
    return;
  } else if (!walletStore.fiatWithdrawAmount || walletStore.fiatWithdrawAmount == 0 || Number(walletStore.fiatWithdrawAmount) < Number(walletStore.fiatSelectItem.chains[0].min_withdraw_amount)) {
    toast(t('the_remaining_wagering_amount') + ' ' + walletStore.fiatSelectItem.chains[0].min_withdraw_amount + walletStore.fiatSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  } else if (Number(walletStore.fiatWithdrawAmount) > Number(walletStore.fiatSelectItem.chains[0].max_withdraw_amount) && walletStore.fiatSelectItem.chains[0].max_withdraw_amount != 0) {
    toast(t('the_maximum_withdrawal') + ' ' + walletStore.fiatSelectItem.chains[0].max_withdraw_amount + walletStore.fiatSelectItem.symbol, { autoClose: 2000, type: 'error' });
    return;
  } else if (!walletStore.fiatWithdrawOptItem) {
    toast(t('please_choose_account_type'));
    return;
  }
  getUserInfo(() => {
    if (!userInfo.value.verify_email || !userInfo.value.enable_otp) {
      walletStore.showDialogVerification = true;
    } else {
      walletStore.showDialogPIN = true;
    }
  });
};
const clickBindEmail = () => {
  walletStore.showDialogVerification = false;
  openBindEmail.value = true;
};
const handleBindEmailClose = () => {
  openBindEmail.value = false;
};
const handleBindEmailSuccess = () => {
  openBindEmail.value = false;
};
const clickBindMfa = () => {
  walletStore.showDialogVerification = false;
  openBindMfa.value = true;
};
const handleBindMfaClose = () => {
  openBindMfa.value = false;
};
const handleBindMfaSuccess = () => {
  openBindMfa.value = false;
};
const getAmountLimit = (val,type) => {
  if (val <= 0 && type === 'min') {
    return '0';
  }else if(val <= 0 && type === 'max') {
    return '∞';
  } else {
    return val;
  }
};
</script>

<template>
  <div v-if="walletStore.tokensInfoList && walletStore.tokensInfoList.length > 0" class="relative box-border max-w-[480px] bg-[#191F33]">
    <!-- 标题 -->
    <div class="flex flex-row justify-between items-center mb-[15px]">
      <div class="text-[18px] text-[#ffffff] font-[700]">{{ t('withdraw') }}</div>
      <!-- <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
          <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
        </svg>
      </div> -->
    </div>
    <!-- tab选项 -->
    <TabSelector class="mb-[15px]" :tabIndex="tabSelect" @change="clickTab" />
    <!-- 加密货币 -->
    <div v-show="tabSelect == 'Crypto'">
      <div class="flex justify-between gap-[10px] mb-[15px]">
        <CoinSelector class="w-1/2" :titleText="t('Withdraw Currency').toUpperCase()" :tabIndex="tabSelect" />
        <ChainSelector class="w-1/2" :titleText="t('Choose Network').toUpperCase()" />
      </div>
      <div class="mb-[15px]">
        <div class="flex justify-between items-center text-[12px] text-[#5B7BAB] text-right font-[700] mb-[5px]">
          <div>{{ t('Withdraw Address').toUpperCase() }}</div>
        </div>
        <el-input v-model="walletStore.cryptoWithdrawAddress" class="my-el-css" size="large" clearable> </el-input>
      </div>
      <div class="mb-[15px]">
        <div class="flex justify-between items-center text-[12px] text-[#5B7BAB] mb-[5px]">
          <div class="font-[700]">{{ t('Withdraw Amount').toUpperCase() }}</div>
          <div>{{ `${t('balance')}: ${walletStore.cryptoSelectItem.balance}` }}</div>
        </div>
        <el-input v-model="walletStore.cryptoWithdrawAmount" class="my-el-css mb-[5px]" size="large" clearable> </el-input>
        <div class="text-[12px] flex flex-col gap-[3px]">
          <div class="flex justify-between items-center">
            <div class="text-[#ffffff]">{{ `${t('Available')}: ${walletStore.cryptoSelectItem.available}` }}</div>
            <div class="text-[#5B7BAB]">{{ `${t('min')}: ${getAmountLimit(walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].min_withdraw_amount,'min')}` }}</div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#ffffff]">{{ `${t('Locked funds')}: ${walletStore.cryptoSelectItem.withdraw_valid_bet_amount}/${walletStore.cryptoSelectItem.withdraw_turnover_amount}` }}</div>
            <div class="text-[#5B7BAB]">{{ `${t('max')}: ${getAmountLimit(walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].max_withdraw_amount,'max')}` }}</div>
          </div>
        </div>
      </div>
      <FeeView class="mb-[15px]" :selectItem="walletStore.cryptoSelectItem" :inputAmount="walletStore.cryptoWithdrawAmount" :fee="walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].withdraw_fee" />
      <div class="w-full mb-[15px]">
        <ButtonItem :btnText="t('confirm')" @click="clickCryptoWithdraw" />
      </div>
      <NociceView type="Withdraw" />
    </div>
    <!-- 法币 -->
    <div v-show="tabSelect == 'Fiat'">
      <div class="flex mb-[15px]">
        <CoinSelector class="w-full flex justify-between items-center" :titleText="t('Withdraw Currency').toUpperCase()" :tabIndex="tabSelect" />
      </div>
      <div class="flex mb-[15px]">
        <PixSelector class="w-full flex justify-between items-center" :titleText="t('Withdraw Account').toUpperCase()" />
      </div>
      <div class="mb-[15px]">
        <div class="flex justify-between items-center text-[12px] text-[#5B7BAB] mb-[5px]">
          <div class="font-[700]">{{ t('Withdraw Amount').toUpperCase() }}</div>
          <div>{{ `${t('balance')}: ${walletStore.fiatSelectItem.balance}` }}</div>
        </div>
        <el-input v-model="walletStore.fiatWithdrawAmount" class="my-el-css mb-[5px]" size="large" clearable> </el-input>
        <div class="text-[12px] flex flex-col gap-[3px]">
          <div class="flex justify-between items-center">
            <div class="text-[#ffffff]">{{ `${t('Available')}: ${walletStore.fiatSelectItem.available}` }}</div>
            <div class="text-[#5B7BAB]">{{ `${t('min')}: ${getAmountLimit(walletStore.fiatSelectItem.chains[0].min_withdraw_amount,'min')}` }}</div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#ffffff]">{{ `${t('Locked funds')}: ${walletStore.fiatSelectItem.withdraw_valid_bet_amount}/${walletStore.fiatSelectItem.withdraw_turnover_amount}` }}</div>
            <div class="text-[#5B7BAB]">{{ `${t('max')}: ${getAmountLimit(walletStore.fiatSelectItem.chains[0].max_withdraw_amount,'max')}` }}</div>
          </div>
        </div>
      </div>
      <FeeView class="mb-[15px]" :selectItem="walletStore.fiatSelectItem" :inputAmount="walletStore.fiatWithdrawAmount" :fee="walletStore.fiatSelectItem.chains[0].withdraw_fee" />
      <div class="w-full mb-[15px]">
        <ButtonItem :btnText="t('confirm')" @click="clickFiatWithdraw" />
      </div>
      <NociceView type="Withdraw" />
    </div>
    <!-- 各种弹窗 -->
    <DialogVerification v-if="walletStore.showDialogVerification" :userInfo="userInfo" @bindEmail="clickBindEmail" @bindMfa="clickBindMfa" />
    <verifiedEmail :show="openBindEmail" :bindEmail="userInfo?.user_email" :isReadOnly="false" @close="handleBindEmailClose" @bindSuccess="handleBindEmailSuccess" />
    <bindMfa :show="openBindMfa" :email="userInfo?.user_email" @close="handleBindMfaClose" @bindSuccess="handleBindMfaSuccess" />
    <DialogPIN v-if="walletStore.showDialogPIN" :tabSelect="tabSelect" />
    <div v-if="walletStore.isLoading" class="fixed left-0 top-0 w-full h-full flex justify-center items-center z-[9999]">
      <vue3-lottie :animationData="loadingAniJson" :width="150" :height="150"></vue3-lottie>
    </div>
  </div>
</template>

<style scoped></style>
