import api from '../api';
import httpManager from '../httpManager';

// 获取在线人数
export const getOnlineUserNumberService = () => {
  return httpManager({
    url: api.serverStatusOnlineUserNumber,
    method: 'get',
    data: {}
  });
};

// 排行榜
export const getBiggestTodayService = () => {
  return httpManager({
    url: api.serverStatusBiggestToday,
    method: 'get',
    data: {}
  });
};
// 排行榜
export const getLatestRoundService = () => {
  return httpManager({
    url: api.serverStatusLatestRound,
    method: 'get',
    data: {}
  });
};
// 获取客服链接
export const getCustomerLinkService = () => {
  return httpManager({
    url: api.tosCustomerServiceLink,
    method: 'get',
    data: {}
  });
};
// 获取首页slots游戏
export const getHomeSlotsService = (param) => {
  return httpManager({
    url: api.openBoxIndexGames,
    method: 'post',
    data: param
  });
};
// 获取首页live casino游戏
export const getHomeLiveCasinoService = (param) => {
  return httpManager({
    url: api.openBoxIndexGames,
    method: 'post',
    data: param
  });
};

// 获取首页live casino游戏
export const getGameProviders = () => {
  return httpManager({
    url: api.openboxGetGameProviders,
    method: 'get'
  });
};
// 获取首页 banner
export const getBannerConfig = () => {
  return httpManager({
    url: api.newapiGetBannerConfig,
    method: 'get'
  });
};
// 活动页报名测试
export const participateInTestService = () => {
  return httpManager({
    url: api.walletJoinTestActivity,
    method: 'post',
    data: {}
  });
};
// 是否参加测试活动
export const getParticipateInTestStateService = () => {
  return httpManager({
    url: api.walletCheckTestActivity,
    method: 'get',
    data: {}
  });
};
// 参加空投活动
export const joinFreeActivityService = (param) => {
  return httpManager({
      url:api.walletJoinJoinFreeActivity,
      method:'post',
      data:param,
  });
}
// 是否参加过空投活动
export const getCheckFreeActivityService = (param) => {
  return httpManager({
      url:`${api.walletCheckFreeActivity}?activity_id=${param.activity_id}`,
      method:'get',
      data:{},
  });
}

// 是否参加测试活动
export const getNotifyInfo = () => {
  return httpManager({
    url: api.getNotifyInfo,
    method: 'get',
    data: {}
  });
};

// 获取promotionList
export const getPromotionList = (params) => {
  return httpManager({
    url: '/tos/get_promotions_list/',
    method: 'get',
    params
  });
};
// 获取promotionDetail
export const getPromotionDetail = (params) => {
  return httpManager({
    url: '/tos/get_promotions_info/',
    method: 'get',
    params
  });
};
// promotion报名
export const joinPromotion = (params) => {
  return httpManager({
    url: '/tos/promotions_sign_up/',
    method: 'post',
    data: params
  });
};
// promotion领奖
export const receivePromotion = (params) => {
  return httpManager({
    url: '/tos/receive_promotions_prize/',
    method: 'post',
    data: params
  });
};
// 获取活动详情内配置的游戏列表
export const getPromotionGames = (param) => {
  return httpManager({
    url: '/openbox/event_games/',
    method: 'post',
    data: param
  });
};

export const getTosPopupConfig = () => {
  return httpManager({
    url: api.getTosPopupConfig,
    method: 'get'
  });
};

// 获取客服列表
export const getCustomerListService = () => {
  return httpManager({
    url: api.tosAvailableCustomerServices,
    method: 'get'
  });
};

// 获取TG引流弹窗
export const getTGDrainageConfigService = (params) => {
  return httpManager({
    url: api.tgDrainageConfig,
    method: 'get',
    params
  });
};

// 获取引流游戏列表
export const getDrainageGameConfigService = () => {
  return httpManager({
    url: api.drainageGameConfig,
    method: 'get'
  });
};

// 获取加密货币和本位币价
export const getTokensPriceService = () => {
  return httpManager({
    url: api.getTokensDetail,
    method: 'get'
  });
};

// 活动悬浮窗
export const getFloatButtonConfigsService = () => {
  return httpManager({
    url: api.tosGetFloatButtonConfigs,
    method: 'get'
  });
};
export const floatButtonConfigsService = (param) => {
  return httpManager({
    url: api.tosClickFloatButton,
    method: 'post',
    data: param
  });
};

// 获取首页大图
export const getHomeLargeImageService = () => {
  return httpManager({
    url: api.tosHomeBannerConfig,
    method: 'get',
    data: {}
  });
};

// 获取底部图
export const getHomeBottomImageService = () => {
  return httpManager({
    url: api.tosBottomBannerConfig,
    method: 'get',
    data: {}
  });
};

// promotion
export const getPromotionRankService = (param) => {
  return httpManager({
    url: `${api.get_promotions_rank_info}?promotion_id=${param.promotion_id}`,
    method: 'get'
  });
}
