<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import menus from '../menus/myMenus.vue';
import myHeader from '../../components/myHeader.vue';
import myBanner from '@/components/banner/myBanner.vue';
// import newBannerList from '@/components/banner/newBannerList.vue';
import myFooter from '@/components/footer/footer.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
import { useI18n } from 'vue-i18n';
import myGameList from '@/components/game/gameList.vue';
// import myProfitList from "@/components/profit/profitList.vue";
import mySearchGameList from '@/components/game/searchGameList.vue';
import mySearchGame from '@/components/game/searchGame.vue';
import myGameSupport from '@/components/game/game_support.vue';
import betsList from '@/components/profit/betsList.vue';
import pageLoading from '@/components/loading/pageLoading.vue';
// import wgDialog from './showPopupConfig.vue';
// import tgEntry from '@/components/notice/tgEntry.vue';
// import { useTGStore } from '@/store/store';
import { getHomeBottomImageService } from '@/assets/network/service/home';
const router = useRouter();

// const appStore = useAppStore();
// const TGStore = useTGStore();
const { t } = useI18n();


const bottomImage = ref(null);
const bottomImageUrl = computed(() => {
  if(bottomImage.value) {
    if(bottomImage.value.image_url && bottomImage.value.image_url.length > 0) {
      return bottomImage.value.image_url
    }else {
      return require('@/assets/images/home/-e-banner_1_0004_banner.webp');
    }
  }
  return null;
})

const fetchBottomImage = async () => {
  try {
    const response = await getHomeBottomImageService();
    if(response.data.banner_config && response.data.banner_config.items.length > 0) {
      bottomImage.value = response.data.banner_config.items[0];
    }
  } catch (error) {
    console.log(error)
  }
}

const bannerClick = () => {
  if(!bottomImage.value) return;

  if (bottomImage.value.link_type == 'External') {
    if (bottomImage.value.link) {
      if (bottomImage.value.link.startsWith('https://') || bottomImage.value.link.startsWith('http://')) {
        window.open(bottomImage.value.link, '_blank');
      } else {
        window.open('https://' + bottomImage.value.link, '_blank');
      }
    }
  } else {
    router.push(bottomImage.value.link);
  }
}

onMounted(() => {
  fetchBottomImage();
})

</script>
<script>
import SlotsImg1 from '@/assets/images/new-version/menu/Slots-1.svg';
import PopularSlotsImg1 from '@/assets/images/new-version/menu/Wingame Popular-1.svg';
import HotGameImg1 from '@/assets/images/new-version/menu/Hot Game-1.svg';
import LiveCasinoImg1 from '@/assets/images/new-version/menu/Live Casino-1.svg';
import NewRelease1 from '@/assets/images/new-version/menu/New Release-1.svg';

import inHouseGameImg from '../../assets/images/home/Frame 427320292.svg';
import gameSupportImg from '../../assets/images/home/Frame 427320293.svg';

// import liveCasinoImg from '../../assets/images/home/Frame 427320294_2.svg';
import jiazai from '@/assets/animation/expott_jiazai.json';

import testimonialsImg1 from '../../assets/images/home/money 2.png';
import testimonialsImg2 from '../../assets/images/home/money 3.png';
import testimonialsImg3 from '../../assets/images/home/money 4.png';
import testimonialsImg4 from '../../assets/images/home/money 1.png';
import testimonialsLevelImg from '../../assets/images/home/ico_b1.png';
import {
  // getCasinoHotGamesService,
  // getCasinoNewGamesService,
  getGameHistoryService,
  // getLiveCasinoListService,
  openboxSearchGames
} from '@/assets/network/service/game';
import { getGameProviders } from '@/assets/network/service/home';
import { getCookieAccessToken, getCookieUserId, checkLoading } from '@/assets/tools/CommonTool';

import lobbyImg1 from '@/assets/images/new-version/category/category1-icon-0.svg';
import lobbyImg2 from '@/assets/images/new-version/category/category1-icon-1.svg';
import slotsImg1 from '@/assets/images/new-version/category/category2-icon-0.svg';
import slotsImg2 from '@/assets/images/new-version/category/category2-icon-1.svg';
import popularSlotsImg1 from '@/assets/images/new-version/category/category3-icon-0.svg';
import popularSlotsImg2 from '@/assets/images/new-version/category/category3-icon-1.svg';
import hotSlotsImg1 from '@/assets/images/new-version/category/category4-icon-0.svg';
import hotSlotsImg2 from '@/assets/images/new-version/category/category4-icon-1.svg';
import liveCasinoImg1 from '@/assets/images/new-version/category/category5-icon-0.svg';
import liveCasinoImg2 from '@/assets/images/new-version/category/category5-icon-1.svg';
import newSlotsImg1 from '@/assets/images/new-version/category/category6-icon-0.svg';
import newSlotsImg2 from '@/assets/images/new-version/category/category6-icon-1.svg';
import BigBanner from '@/components/banner/BigBanner.vue';
import LiveWins from '@/pages/home/module/LiveWins.vue';
import CoinPrice from '@/pages/home/module/CoinPrice.vue';

// import {getGameProviders} from "@/assets/network/service/home";
export default {
  name: 'App',
  components: {},
  watch: {
    providerList: function (newVal) {
      this.myProviderList = newVal;
    }
  },
  data() {
    return {
      menuChangeIndex: 0,
      categoryList: [
        { code: 'Lobby', name: 'lobby', img: lobbyImg1, selectedImg: lobbyImg2, page: '/' },
        { code: 'Slots', name: 'slots', img: slotsImg1, selectedImg: slotsImg2, page: '/slots/allSlots' },
        { code: 'Popular', name: 'popular', img: popularSlotsImg1, selectedImg: popularSlotsImg2, page: '/slots/popularSlots' },
        { code: 'Hot Game', name: 'hot', img: hotSlotsImg1, selectedImg: hotSlotsImg2, page: '/slots/hotSlots' },
        { code: 'Live Casino', name: 'live', img: liveCasinoImg1, selectedImg: liveCasinoImg2, page: '/liveCasino' },
        { code: 'New', name: 'new', img: newSlotsImg1, selectedImg: newSlotsImg2, page: '/slots/newSlots' }
      ],
      loading: false,
      slotsKey: 0,
      popularKey: 0,
      newKey: 0,
      hotKey: 0,
      liveKey: 0,
      inHouseGameList: [],
      myProviderList: [],
      profitList: [],
      providerList: [],
      gameList: [],
      gameList2: [],
      slotsGameList: [],
      slotsGame: {
        gameCode: 'slots',
        gameImage: SlotsImg1,
        showBottom: true,
        isAll: true,
        allImg: '',
        allUrl: '/slots/allSlots'
      },
      game: {
        gameCode: 'popular_slots',
        gameImage: PopularSlotsImg1,
        showBottom: true,
        isAll: true,
        allImg: '',
        allUrl: '/slots/popularSlots'
      },
      hotGame: {
        gameCode: 'hot_slots',
        gameImage: HotGameImg1,
        showBottom: true,
        isAll: true,
        allImg: '',
        allUrl: '/slots/hotSlots'
      },
      liveCasino: {
        gameCode: 'live_casino',
        gameImage: LiveCasinoImg1,
        showBottom: true,
        isAll: true,
        allImg: '',
        allUrl: '/liveCasino'
      },
      game2: {
        gameCode: 'new_slots',
        gameImage: NewRelease1,
        showBottom: true,
        isAll: true,
        allImg: '',
        allUrl: '/slots/newSlots'
      },
      inHouseGame: {
        gameCode: 'in_house_game',
        gameImage: inHouseGameImg,
        showBottom: true
      },
      gameSupport: {
        gameCode: 'game_support',
        gameImage: gameSupportImg,
        showBottom: false
      },
      testimonialsList: [
        {
          image: testimonialsImg1,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg2,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg3,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg4,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        }
      ],
      testimonials: {
        gameCode: 'testimonials',
        showBottom: true
      },
      indexGameSelect: '',
      indexGameSelect2: 'lobby',
      selectGame: { code: 'Lobby', name: 'lobby', img: slotsImg1, selectedImg: slotsImg1, page: '/' },
      showMenu: true,
      isGameSearch: false,
      searchText: '',
      searchResult: [],
      online_user_number: 0,
      slots_index: 0,
      popular_slots_index: 0,
      news_slots_index: 0,
      hot_slots_index: 0,
      live_slots_index: 0,
      slots_page_size: 20,
      queryObjectLoading: {
        banner: false,
        slots: true,
        popular: true,
        hot: true,
        news: true,
        live: true,
        providers: false,
        profit: false,
        single: false
      },
      singleGameKey: 0,
      singleGameList: [],
      singleGameTotal: 0,
      querySize: 0,
      searchParams: {
        game_type: '',
        name: ''
      },
      isLogin: false,
      showLogin: false,
      height: 500,
      tosPopupConfigs: [],
      showTosPopupConfig: false,
      walletList: []
    };
  },
  created() {
    this.slotsGameList = [];
    this.gameList = [];
    this.gameList2 = [];
    this.hotGameList = [];
    this.liveCasinoGameList = [];
    let menuStr = localStorage.getItem('showMenu');
    if (menuStr) {
      this.showMenu = menuStr === 'true';
    } else {
      this.showMenu = true;
    }
    this.getSlotsGamesService();
    this.getCasinoPopularGamesService();
    this.getCasinoNewGamesService();
    this.getCasinoHotGamesService();
    this.getLiveCasinoListService();
    this.getGameProviders();
    // this.getTosPopupConfig();
    let token = getCookieAccessToken();
    if (token) {
      this.isLogin = true;
    }
    this.indexProfit = this.isLogin ? 'me' : 'all';
    this.getGameHistoryService();
    this.height = window.innerHeight + 'px';
  },
  methods: {
    // closeWGDialog() {
    //   this.showTosPopupConfig = false;
    //   this.getTGConfig('INDEX');
    // },
    // getTGConfig(type) {
    //   const TGStore = useTGStore();
    //   TGStore.getConfigService(type);
    // },
    loginAfter(walletList) {
      this.walletList = walletList;
    },
    hideShowLogin() {
      this.showLogin = false;
    },
    parentLogin() {
      this.showLogin = true;
      this.$emit('parentLogin');
    },
    gameInit() {
      this.slots_index = 0;
      this.popular_slots_index = 0;
      this.news_slots_index = 0;
      this.hot_slots_index = 0;
      this.live_slots_index = 0;
      this.searchParams.name = '';
      this.getSlotsGamesService();
      this.getCasinoPopularGamesService();
      this.getCasinoNewGamesService();
      this.getCasinoHotGamesService();
      this.getLiveCasinoListService();
    },
    // getTosPopupConfig() {
    //   getTosPopupConfig()
    //     .then((response) => {
    //       if (response.data.popup_config) {
    //         this.showTosPopupConfig = true;
    //         this.tosPopupConfigs = response.data.popup_config.items;
    //       }
    //     })
    //     .catch((error) => {
    //       // this.queryObjectLoading.providers = false
    //       console.log(error);
    //     });
    // },
    getGameProviders() {
      this.queryObjectLoading.providers = true;
      getGameProviders()
        .then((response) => {
          this.queryObjectLoading.providers = false;
          this.providerList = response.data.data;
        })
        .catch((error) => {
          this.queryObjectLoading.providers = false;
          console.log(error);
        });
    },
    getGameHistoryService() {
      this.queryObjectLoading.profit = true;
      try {
        getGameHistoryService({
          user_id: this.indexProfit == 'me' ? Number(getCookieUserId()) : '',
          page: 1,
          page_size: 10
        })
          .then((response) => {
            this.queryObjectLoading.profit = false;
            // if(this.profitList.length > 0){
            //   this.profitList = this.profitList.concat(response.data.data)
            // }else{
            //   this.profitList = response.data.data
            // }
            this.profitList = response.data.data;
          })
          .catch((error) => {
            this.queryObjectLoading.profit = false;
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    nextGetSlotsGamesService() {
      this.slots_index++;
      this.getSlotsGamesService();
    },
    getSlotsGamesService() {
      const that = this;
      try {
        this.queryObjectLoading.slots = true;
        that.loading = true;
        openboxSearchGames({
          game_type: 'CasinoSlot',
          game_provider: this.indexGameSelect ? [this.indexGameSelect] : [],
          page_name: '',
          start: that.slots_index * this.slots_page_size,
          end: (that.slots_index + 1) * this.slots_page_size
        })
          .then((response) => {
            this.queryObjectLoading.slots = false;
            that.loading = false;
            this.slotsKey++;
            this.querySize++;
            if (that.slots_index > 0) {
              this.slotsGameList = this.slotsGameList.concat(response.data.data);
            } else {
              this.slotsGameList = response.data.data;
            }
          })
          .catch((error) => {
            this.queryObjectLoading.slots = false;
            that.loading = false;
            this.querySize++;
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    nextGetCasinoPopularGamesService() {
      this.popular_slots_index++;
      this.getCasinoPopularGamesService();
    },
    getCasinoPopularGamesService() {
      const that = this;
      try {
        this.queryObjectLoading.popular = true;
        that.loading = true;
        openboxSearchGames({
          game_type: '',
          game_provider: this.indexGameSelect ? [this.indexGameSelect] : [],
          page_name: 'popular',
          start: that.popular_slots_index * this.slots_page_size,
          end: (that.popular_slots_index + 1) * this.slots_page_size
        })
          .then((response) => {
            this.queryObjectLoading.popular = false;
            that.loading = false;
            this.popularKey++;
            this.querySize++;
            if (that.popular_slots_index > 0) {
              this.gameList = this.gameList.concat(response.data.data);
            } else {
              this.gameList = response.data.data;
            }
          })
          .catch((error) => {
            this.queryObjectLoading.popular = false;
            that.loading = false;
            this.querySize++;
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    nextGetCasinoHotGamesService() {
      this.hot_slots_index++;
      this.getCasinoHotGamesService();
    },
    getCasinoHotGamesService() {
      const that = this;
      this.queryObjectLoading.hot = true;
      openboxSearchGames({
        game_type: '',
        game_provider: this.indexGameSelect ? [this.indexGameSelect] : [],
        page_name: 'hot',
        start: that.hot_slots_index * this.slots_page_size,
        end: (that.hot_slots_index + 1) * this.slots_page_size
      })
        .then((response) => {
          this.queryObjectLoading.hot = false;
          this.newKey++;
          that.querySize++;
          that.hotGameList = response.data.data;
          console.log('hot query', that.querySize);
          console.log('hotGameList size', that.hotGameList.length);
        })
        .catch((error) => {
          this.queryObjectLoading.hot = false;
          this.querySize++;
          console.log(error);
        });
    },
    nextGetCasinoNewGamesService() {
      this.news_slots_index++;
      this.getCasinoNewGamesService();
    },
    getCasinoNewGamesService() {
      const that = this;

      this.queryObjectLoading.news = true;
      openboxSearchGames({
        game_type: '',
        game_provider: this.indexGameSelect ? [this.indexGameSelect] : [],
        page_name: 'new',
        start: that.news_slots_index * this.slots_page_size,
        end: (that.news_slots_index + 1) * this.slots_page_size
      })
        .then((response) => {
          this.queryObjectLoading.news = false;
          this.hotKey++;
          this.querySize++;
          this.gameList2 = response.data.data;
          console.log('all new slots length' + this.gameList2.length);
        })
        .catch((error) => {
          this.queryObjectLoading.news = false;
          this.querySize++;
          console.log(error);
        });
    },

    nextGetLiveCasinoListService() {
      this.live_slots_index++;
      this.getLiveCasinoListService();
    },
    getLiveCasinoListService() {
      const that = this;

      this.queryObjectLoading.live = true;
      openboxSearchGames({
        game_provider: this.indexGameSelect ? [this.indexGameSelect] : [],
        game_type: 'CasinoLive',
        start: that.live_slots_index * this.slots_page_size,
        end: (that.news_slots_index + 1) * this.slots_page_size
      })
        .then((response) => {
          this.queryObjectLoading.live = false;
          this.liveKey++;
          this.querySize++;
          this.liveCasinoGameList = response.data.data;
        })
        .catch((error) => {
          this.queryObjectLoading.live = false;
          this.querySize++;
          console.log(error);
        });
    },
    searchSingle() {
      let params = {
        end: this.slots_page_size,
        game_provider: [],
        game_type: 'CasinoSlot',
        name: '',
        page_name: '',
        start: 0
      };
      if (this.indexGameSelect2 == 'slots') {
        params = {
          end: this.slots_page_size,
          game_provider: [],
          game_type: 'CasinoSlot',
          name: '',
          page_name: '',
          start: 0
        };
      } else if (this.indexGameSelect2 == 'popular' || this.indexGameSelect2 == 'hot' || this.indexGameSelect2 == 'new') {
        params = {
          end: this.slots_page_size,
          game_provider: [],
          game_type: '',
          name: '',
          page_name: this.indexGameSelect2,
          start: 0
        };
      } else if (this.indexGameSelect2 == 'Baccarat') {
        params = {
          end: this.slots_page_size,
          game_provider: [],
          game_type: '',
          game_sub_type: this.indexGameSelect,
          name: '',
          page_name: '',
          start: 0
        };
      } else if (this.indexGameSelect2 == 'live') {
        params = {
          end: this.slots_page_size,
          game_provider: [],
          game_type: 'CasinoLive',
          name: '',
          page_name: '',
          start: 0
        };
      } else {
        return;
      }
      const that = this;
      that.queryObjectLoading.single = true;
      this.singleGameList = [];
      that.loading = true;
      openboxSearchGames(params)
        .then((response) => {
          this.queryObjectLoading.single = false;
          this.singleGameKey++;
          this.singleGameList = response.data.data;
          this.singleGameTotal = response.data.total_count;
        })
        .catch((error) => {
          this.queryObjectLoading.single = false;
          that.loading = false;
          this.querySize++;
          console.log(error);
        });
    },
    gameSelectChange(indexGameSelect, p) {
      this.selectGame = p;
      this.querySize = 0;
      // 这个是游戏品牌过滤，首页目前已取消改功能
      // this.indexGameSelect = indexGameSelect
      // 首页游戏搜索按照分类进行查询
      this.indexGameSelect2 = indexGameSelect;
      if (this.indexGameSelect2 !== 'lobby') {
        this.searchSingle();
      } else {
        this.gameInit();
      }
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
      if (this.indexProfit == 'me' && !this.isLogin) {
        this.indexProfit = 'all';
        return;
      }
      this.getGameHistoryService();
    },
    gameCategoryChange(gameCategory) {
      this.gameCategory = gameCategory;
    },
    showMenuChange() {
      this.showMenu = !this.showMenu;
      localStorage.setItem('showMenu', this.showMenu);
      console.log(localStorage.getItem('showMenu'));
    },
    enterSearch() {
      console.log(this.searchText);
      if (!this.searchText && this.searchText.trim().length <= 3) {
        this.searchText = this.searchText.trim();
        return;
      }
      this.searchResult = [];
      this.searchParams.name = this.searchText;
      this.openboxSearchGames();
    },
    openboxSearchGames() {
      openboxSearchGames(this.searchParams)
        .then((response) => {
          this.searchResult = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    },
    jumpCategory() {
      window.sessionStorage.setItem('sortBy', JSON.stringify([]));
      this.$router.push(this.selectGame.page);
    },
    menuChange() {
      this.menuChangeIndex++;
      console.log('-----------------menuChangeIndex----------------', this.menuChangeIndex);
    },
    changeBanner(flag) {
      this.queryObjectLoading.banner = flag;
    }
  }
};
</script>
<template>
  <div class="root_div home" @click="isGameSearch = false">
    <menus @menuChange="menuChange"></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></my-header>
      <div class="main-content" style="width: 100%; padding: 15px 0">
        <div class="body">
          <LiveWins />
        </div>
      </div>
      <div v-if="!$isMobile()" class="main-content" style="width: 100%">
        <BigBanner />
      </div>
      <page-loading :animationData="jiazai" :container-height="height" :show="checkLoading(queryObjectLoading) && queryObjectLoading.banner"></page-loading>
      <div class="main-content mt-10" style="width: 100%" v-show="!queryObjectLoading.banner">
        <div class="body">
          <my-banner @changeBanner="changeBanner"></my-banner>
        </div>
        <!-- <new-bannerList /> -->
        <!-- <div style="clear: both"></div> -->
      </div>
      <div class="main-content" style="width: 100%; padding: 15px 0">
        <div class="body">
          <CoinPrice />
        </div>
      </div>
      <my-search-game :show="!queryObjectLoading.banner" />
      <div class="home-tabs main-content tool-bar-search top-1rem" style="" v-show="!queryObjectLoading.banner">
        <div class="body tool-bar tool-bar-search">
          <div class="tool-bar-tabs scrollbar">
            <div>
              <div :class="(indexGameSelect2 == p.name ? 'selected' : '') + (index == 0 ? ' first ' : '') + ' item' + (index == categoryList.length - 1 ? ' last' : '')" :key="index" v-for="(p, index) in categoryList" @click="gameSelectChange(p.name, p)">
                <div class="item-content" @mouseover="p.hover = true" @mouseleave="p.hover = false">
                  <img class="" v-show="!p.hover && indexGameSelect2 !== p.name" :src="p.img" />
                  <img class="" v-show="p.hover || indexGameSelect2 == p.name" :src="p.selectedImg" />
                  {{ p.code }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <page-loading :animationData="jiazai" :container-height="'37.5rem'" :containerHeightMobile="'22rem'" :show="checkLoading(queryObjectLoading) && !queryObjectLoading.banner"></page-loading>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 !== 'lobby'">
        <div class="body game-list-content">
          <div class="game-title" style="padding: 0">
            <div class="game-title-name">
              <img :src="selectGame.img" />
              <div>{{ t(selectGame.code).toUpperCase() }}</div>
            </div>
          </div>
          <div v-show="singleGameList.length > 0">
            <my-search-game-list :game-list="singleGameList" :style="'padding:0;'"></my-search-game-list>
          </div>
        </div>
      </div>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 !== 'lobby'">
        <div class="body">
          <div v-if="singleGameTotal > singleGameList.length" style="display: flex; justify-content: center; width: 100%; margin: 0.5rem 0">
            <div @click="jumpCategory()" class="load-more" style="width: auto">
              {{ t('view_all') }}<span style="padding: 0 0.3rem">{{ singleGameTotal }}</span
              >{{ t(selectGame.code) }}
            </div>
          </div>
        </div>
      </div>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 == 'lobby'">
        <my-game-list :gameList="slotsGameList" :game="slotsGame" :key="slotsKey" :menuChangeIndex="menuChangeIndex" @next="nextGetSlotsGamesService"></my-game-list>
      </div>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 == 'lobby'">
        <my-game-list :gameList="gameList" :game="game" :key="popularKey" :menuChangeIndex="menuChangeIndex" @next="nextGetCasinoPopularGamesService"></my-game-list>
      </div>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 == 'lobby'">
        <my-game-list :gameList="hotGameList" :game="hotGame" :key="hotKey" :menuChangeIndex="menuChangeIndex" @next="nextGetCasinoHotGamesService"></my-game-list>
      </div>
      <div class="main-content web phone" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 == 'lobby'">
        <my-game-list :gameList="inHouseGameList" :game="inHouseGame" :menuChangeIndex="menuChangeIndex"></my-game-list>
      </div>
      <my-game-support v-show="!checkLoading(queryObjectLoading)"></my-game-support>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 == 'lobby'">
        <my-game-list :gameList="liveCasinoGameList" :game="liveCasino" :key="liveKey" :menuChangeIndex="menuChangeIndex" @next="nextGetLiveCasinoListService"></my-game-list>
      </div>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) && indexGameSelect2 == 'lobby'">
        <my-game-list :gameList="gameList2" :game="game2" :key="newKey" :menuChangeIndex="menuChangeIndex" @next="nextGetCasinoNewGamesService"></my-game-list>
      </div>
      <div class="main-content">
        <div class="body top-1rem" v-if="bottomImage">
          <div class="home-banner" @click="bannerClick()">
            <div class="banner-bg">
              <img :src="bottomImageUrl" style="width: 100%" />
            </div>
            <div class="banner-text-div">
              <div class="text-left">
                <div class="banner-type">
                  <div>{{ bottomImage.desc }}</div>
                </div>
                <div class="banner-title omit-multi" style="--clamp-num: 2">{{ bottomImage.title }}</div>
                <div class="banner-desc omit-multi" style="--clamp-num: 4">{{ bottomImage.sub_title }}</div>
              </div>
              <div class="banner-btn" v-if="bottomImage.switch">
                <div>{{ bottomImage.button_text }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="main-content" v-show="!checkLoading(queryObjectLoading) || !queryObjectLoading.profit">
        <bets-list @parentLogin="parentLogin"></bets-list>
      </div>

      <div class="main-content" v-show="!checkLoading(queryObjectLoading) || !queryObjectLoading.providers">
        <my-testimonials-list :gameList="testimonialsList" :game="testimonials"></my-testimonials-list>
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
    <div style="width: 100vw; height: 100vh; position: absolute" v-show="showTosPopupConfig && tosPopupConfigs.length > 0">
      <!-- <wg-dialog :show="!appStore.sportsShow && (showTosPopupConfig && tosPopupConfigs.length > 0)" :notifyDates="tosPopupConfigs" @close="closeWGDialog"> </wg-dialog> -->
    </div>
    <!-- 这里添加TG引流 -->
    <!-- <tg-entry :show="TGStore.show" :config="TGStore.config" @close="TGStore.updateShow(false)" /> -->
  </div>
</template>

<style scoped>
.web .tool-bar .tool-bar-tabs.scrollbar div {
  overflow-x: hidden;
}
.web .tool-bar .tool-bar-tabs.scrollbar div:hover {
  overflow-x: auto;
}
.phone .main-content {
  padding-top: 0;
}

.home .tool-bar .tool-bar-tabs {
  width: 100%;
  padding: 0;
  overflow-x: auto;
  display: inline-flex;
}
.home-tabs .tool-bar .tool-bar-tabs .item {
  margin: 0;
  border-radius: 0;
  padding: 0;
  padding: 0 0.5rem 0 0;
  background-color: transparent;
  width: 118px;
}
.home-tabs .tool-bar .tool-bar-tabs .item.selected {
  background-color: transparent;
}
.home-tabs .tool-bar .tool-bar-tabs .item.selected .item-content {
  border-radius: 8px;
  /* color: var(--tab-selected-color); */
  color: #e5f9ff;
  text-shadow:
    0px 0px 8px #1184fa,
    0px 0px 12px #1184fa;
  background-color: #222942;
}
.home-tabs .tool-bar .tool-bar-tabs .item.first {
  border-radius: 8px;
  /* padding: 0 0.5rem 0 0; */
}
.home-tabs .tool-bar .tool-bar-tabs .item .item {
  border-radius: 0;
}
.home-tabs .tool-bar .tool-bar-tabs .item.last {
  border-radius: 8px;
  /* padding: 0; */
}
.home-tabs .tool-bar .tool-bar-tabs .item .item-content {
  /* color: var(--home-tab-color); */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 84px;
  padding: 0 0.5rem;
  line-height: 1;
  gap: 12px;
  min-width: 84px;
}
.home-tabs .tool-bar .tool-bar-tabs .item .item-content:hover {
  /* color: var(--tab-selected-color); */
  color: #e5f9ff;
  text-shadow:
    0px 0px 8px #1184fa,
    0px 0px 12px #1184fa;
  background-color: #222942;
}
.home-tabs .tool-bar .tool-bar-tabs .item:hover {
  background-color: transparent;
}
*:before,
*:after {
  box-sizing: border-box;
}
.padding-left-right-0_5 {
  padding-right: 0.5rem;
}

.tool-bar .tool-bar-tabs div {
  height: auto;
}


.web .home-banner {
  width: 100%;
  min-height: 200px;
}
.home-banner {
  width: calc(100% - 10px);
  background-color: var(--home-banner-bg-color);
  border-radius: 0.5rem;
  margin: 0 5px;
  cursor: pointer;
  height: 200px;
  position: relative;
  overflow: hidden;
  .banner-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      /* object-fit: cover; */
    }
  }

  .banner-text-div {
    position: relative;
    width: 70%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    .text-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
.home-banner:hover {
  opacity: 0.8;
}
.home-banner .banner-left {
  text-align: left;
  width: 55%;
  margin: 1.125rem 0 1.125rem 1.125rem;
}
.home-banner .banner-left div {
  margin-right: 1rem;
}
.home-banner .banner-right {
  margin: 1.125rem 1.125rem 1.125rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  background-color: var(--home-banner-bg2-color);
  border-radius: 1.125rem;
}
.home-banner .banner-title {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: #ffffff;
  white-space: break-spaces;
  text-align: left;
}
.home-banner .banner-type {
  display: flex;
  font-weight: 700;
  font-size: 1rem;
}
.home-banner .banner-type div {
  background-color: var(--color-8ea3c3);
  border-radius: 0.125rem;
  color: #0c131d;
  line-height: 1.5rem;
  padding: 0 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-banner .banner-desc {
  font-family: Verdana;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  white-space: break-spaces;
  text-align: left;
}
.home-banner .banner-btn {
  line-height: 1.6rem;
  display: flex;
  bottom: 1.125rem;
}
.home-banner .banner-btn div {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 1.8rem;
  font-size: 0.9rem;
  padding: 0 1.6rem;
  cursor: pointer;
  font-weight: 700;
}
</style>
